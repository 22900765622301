export const webDataSourceDE = [{
    lan: "De",
    navbarData: [{
        start: "Startseite",
        translation: "Übersetzung",
        interpreting: "Dolmetschen",
        tutoring: "Nachhilfe",
        other: "Weitere Leistungen",
        family: "Familienzusammenführung",
        document: "Urkundenbeschaffung",
        offer: "Angebotsanfrage",
        blog: "Sprachblog",
        about: "Über uns",
        team: "Team",
        vision: "Vision",
        logo: "/images/logo_.png",
    }],
    homePageData: [{
        introduction: [{
            title1: "KAZA Sprachdienste",
            title2: "Ihr Übersetzungsbüro in Leipzig",
            title3: "Das zeichnet uns aus:",
            list1: "Langjährige Erfahrung",
            list2: "Fachkenntnisse in verschiedenen Bereichen",
            list3: "Umfassende Begleitung Schritt für Schritt",
            list4: "Individuelle Beratung",
            text1: "Bei KAZA Sprachdienste finden Sie Sprach- und Nachhilfedienstleistungen, die von professionellen Übersetzungen und Sprachmittlung in zahlreichen Sprachen über besondere Unterstützungsleistungen im Bereich der Familienzusammenführung für unsere arabischen Kunden bis hin zu Nachhilfevermittlung für Schüler reichen. ",
            text2: "Das vom Geschäftsführer Ahmed Abd El Aal gegründete Büro befindet sich seit 2019 im Herzen der Stadt Leipzig. Unsere Vision ist es, einen Raum zu schaffen, in dem eine Integration von Sprachen, Kulturen und Bildung ermöglicht wird. In enger Kooperation mit unseren vielen ambitionierten und erfahrenen FachübersetzerInnen, DolmetscherInnen und LehrerInnen konnten wir uns stetig weiterentwickeln, sodass KAZA Sprachdienste heute neben seinem Stand als etabliertes Übersetzungsbüro auch weitere Sprach- und Nachhilfedienstleistungen und damit seinen SchülerInnen Unterstützung in einer Vielzahl von Fächern anbietet. ",
            text3: "",
            text4: "Ihr Team von KAZA Sprachdienste freut sich auf die Zusammenarbeit mit Ihnen!",
            ownerImage: "/images/hero2.png",
            ownername: "Ahmed Abd El Aal",
            description: "INHABER & GESCHÄFTSFÜHRER",
            ownerLogo: "/images/bdue.png",
            openTimeTitle1: "Sie erreichen uns unter",
            telephone: "0341 / 860 872 73",
            openTime: "Mo - Fr 09:30 - 18:30",
            hotLineTitle: "Hotline",
            hotLineNumber: " 0160 / 9963 33 17",
            hotLineDescription: "außerhalb der Bürozeiten, an Wochenenden & Feiertagen",
            address: "Hans-Poeche-Straße 5",
            plz: "04103 Leipzig",
            email: "info@kaza-sprachdienste.de",
            teamImage: "/images/hero1.jpeg",
        }],
        ourServices: [{
            servicesTitle: "Unsere Leistungen",
            serviceName1: "Übersetzung",
            serviceDescription1: "Alle offiziellen Dokumente können bei uns übersetzt werden und werden durch unsere ausgebildeten FachübersetzerInnen mehrfach geprüft. Wir stehen für Kundennähe, Pünktlichkeit und Zuverlässigkeit.",
            serviceName2: "Dolmetschen",
            serviceDescription2: "Für jeden Anlass bieten wir Ihnen Dolmetschdienste durch unsere professionellen Dolmetscher in verschiedene Sprachen an - von Gerichtsverhandlungen über Behördengänge bis hin zu Fachsitzungen und Arztgesprächen.",
            serviceName3: "Nachhilfe",
            serviceDescription3: "Unsere ausgebildeten Lehrkräfte stehen Ihnen für privaten Nachhilfeunterricht in verschiedenen Fächern (Sprachen und Naturwissenschaften) zur Verfügung.",
            serviceName4: "Familienzusammenführung",
            serviceDescription4: "In direktem Kundenkontakt sorgen wir für den reibungslosen Ablauf aller Formalitäten bis zur Ankunft des Ehepartners oder Familienangehörigen in Deutschland.",
            serviceName5: "Urkundenbeschaffung",
            serviceDescription5: "Durch die enge Zusammenarbeit mit einem spezialisierten Notariatsbüro in Ägypten und Syrien beschaffen wir Ihre benötigten Unterlagen und kümmern uns für Sie um deren amtliche Legalisierung bei den zuständigen Stellen.",
        }],
        wisdom: "Sprache ist der Schlüssel zur Welt",
        wisdomAuthor: "W.V. HUMBOLDT",
        offerText1: "Sie sind interessiert an unseren Dienstleistungen?",
        offerText2: "Dann nutzen Sie unsere Angebotsanfrage und fordern Sie noch heute Ihr individuelles Angebot an!",
        offerButton: "Angebotsanfrage",
        socialMedia: "Unsere Social-Media-Netzwerke",
        phone: "Tel.:",
        email: "E-Mail: "
    }],
    translationPageData: [{
        title: "Übersetzungen",
        titleImage: "/images/uebersetzungsbuero.jpg",
        title1: "Beglaubigte Übersetzungen",
        title1Description: "Durch unser professionelles, vielfältiges und erfahrenes Team von FachübersetzerInnen bieten wir Ihnen die sorgfältige und zuverlässige Übersetzungen Ihrer Zeugnisse, Urkunden und sonstiger Dokumente. Aufgrund unserer langjährigen Erfahrung wissen wir, welche Formalitäten bei der Übersetzung offizieller Dokumente beachtet werden müssen.",
        title2: "Fachübersetzungen",
        title2Description1: "Die verschiedensten Branchen, Fachbereiche und Spezialisierungen werden bei uns von sprachlichen und fachlichen Experten abgedeckt. Brauchen Sie eine technische Übersetzung für Ihr Unternehmen, die Übersetzung eines medizinischen Gutachtens, eines Gerichtsurteils, eines Kaufvertrags oder einer anderen fachspezifischen Textsorte, dann sind Sie bei uns genau richtig. ",
        title2Description2: "Für jeden Fachbereich, angefangen von Wirtschaft und Technik über Medizin und Recht bis hin zu Literatur und Marketing, finden Sie bei uns den richtigen Ansprechpartner. Wir vermitteln Ihnen den passenden sprachlich sowie fachlich spezialisierten Übersetzer. ",
        title3: "Qualitätsgarantie",
        title3Description1: "Ausgezeichnete Qualität der Übersetzungen in Rücksprache mit unserem Kunden steht bei uns an erster Stelle. Daher orientieren wir uns an genauen Richtlinien, die sicherstellen, dass die Ausgangstexte und Dokumente inhaltsgetreu, korrekt und hochwertig in die gewünschte Sprache übertragen werden. ",
        title3Description2: "Unsere Übersetzungen werden nach dem 4-Augen-Prinzip angefertigt. Erst nach der sorgfälgtigen Überprüfung der Richtigkeit und Vollständigkeit werden die Übersetzungen von unseren vereidigten ÜbersetzerInnen mit Unterschrift und Stempel beglaubigt. ",
        title3Description3: "Wir garantieren Ihnen die pünktliche Lieferung Ihrer übersetzten Dokumente zu dem vereinbarten Termin. KAZA steht für Kundennähe, um allen Ihren Ansprüchen an die Übersetzungen gerecht zu werden.",
        title4: "Sprachen",
        title4Description1: "Sie benötigen eine Übersetzung für eine bestimmte Sprache, oder Sie sind als Geschäftspartner auf der Suche nach der Übersetzung Ihrer Texte in mehrere Sprachen und das aus einer Hand? Dann sind Sie bei uns genau richtig. Durch unser großes Netzwerk an qualifizierten Übersetzern stehen Ihnen bei uns über 30 verschiedene Sprachenpaare zur Verfügung. ",
        title4Description2: "Wir übersetzen unter anderem aus und in folgende Sprachen: ",
        title4Description3: "Afghanisch | Afrikaans | Albanisch | Amharisch | Arabisch | Armenisch | Aserbaidschanisch | Bengali | Bosnisch | Bulgarisch | Chinesisch | Dänisch | Dari | Deutsch | Englisch | Estnisch | Farsi | Finnisch | Französisch | Georgisch | Griechisch | Hindi | Indonesisch | Italienisch | Japanisch | Kasachisch | Koreanisch | Kroatisch | Kurdisch | Lettisch | Litauisch | Makedonisch | Montenegrinisch | Niederländisch | Norwegisch | Paschtu | Persisch | Polnisch | Portugiesisch | Punjabi | Rumänisch | Russisch | Schwedisch | Serbisch | Serbokroatisch | Slowakisch | Slowenisch | Spanisch | Thailändisch | Türkisch | Tschechisch | Ungarisch | Urdu | Ukrainisch | Vietnamesisch | Weißrussisch",
        title4Description4: "Falls Sie eine Übersetzung aus einer oder in eine bestimmte Sprache wünschen, die hier nicht aufgeführt ist, wenden Sie sich gerne persönlich an uns oder schicken uns eine Angebotsanfrage.",
        contactTitle: "Kontaktinformationen :",
        contactPerson: "Dana Al Khawam",
        role: "BÜROLEITERIN & KUNDENDIENST",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    interpretingPageData: [{
        title: "Dolmetschen",
        titleImage: "/images/dolmetscher.jpg",
        title1: "Suchen Sie zuverlässige sprachliche Unterstützung bei Behördengängen oder Arztbesuchen?",
        title2: "Benötigen Sie einen beeidigten Dolmetscher oder eine Dolmetscherin?",
        title3: "Wünschen Sie eine professionelle Simultan- oder Konsekutivverdolmetschung für eine Tagung oder eine Konferenz?",
        title4: "Möchten Sie oder Ihr Unternehmen sprachliche Vermittlung für Vertragsgespräche?",
        text1: "Für jeden Anlass finden Sie bei uns professionelle Dolmetschdienste in verschiedene Sprachen. Aus unserem Netzwerk qualifizierter DolmetscherInnen bieten wir Ihnen sprachliche Begleitung für Gerichtsverhandlungen, Tagungen, Konferenzen, Interviews, Verhandlungen und viele weitere Anlässe. ",
        text2: "Bei uns finden Sie eine sachorientierte sprachliche und kulturelle Vermittlung, indem wir für eine fachkompetente und reibungslose Verständigung während Ihres Gesprächs sorgen.",
        text3: "Alle unsere DolmetscherInnen sind professionell ausgebildet und sowohl linguistische als auch interkulturelle Experten, die dafür sorgen, dass Ihr Gespräch reibungslos, unkompliziert  und verständlich abläuft. ",
        text4: "Unsere Dolmetscherdienste stehen sowohl Privatpersonen als auch öffentlichen Institutionen und internationalen Unternehmen zur Verfügung, da unsere erfahrenen DolmetscherInnnen nicht nur verschiedenste Branchen abdecken, sondern sich auch auf jede Gesprächssituation individuell einstellen.",
        text5: "Der persönliche Kundenkontakt steht bei uns an erster Stelle, um die beste Qualität für Ihr Anliegen zu gewährleisten. Wir gehen auf Ihre Wünsche und Vorstellungen ein und stellen den direkten Kontakt zu einem für Ihren Bedarf passenden Dolmetscher her. ",
        title5: "Sprachen",
        title5Description1: "Uns zeichnet ein großes Sprachangebot für Dolmetscheinsätze jeder Art aus. Für folgende Sprachen finden Sie bei uns den richtigen Partner: ",
        title5Description2: "Afghanisch | Afrikaans | Albanisch | Amharisch | Arabisch | Armenisch | Aserbaidschanisch | Bengali | Bosnisch | Bulgarisch | Chinesisch | Dänisch | Dari | Deutsch | Englisch | Estnisch | Farsi | Finnisch | Französisch | Georgisch | Griechisch | Hindi | Indonesisch | Italienisch | Japanisch | Kasachisch | Koreanisch | Kroatisch | Kurdisch | Lettisch | Litauisch | Makedonisch | Montenegrinisch | Niederländisch | Norwegisch | Paschtu | Persisch | Polnisch | Portugiesisch | Punjabi | Rumänisch | Russisch | Schwedisch | Serbisch | Serbokroatisch | Slowakisch | Slowenisch | Spanisch | Thailändisch | Türkisch | Tschechisch | Ungarisch | Urdu | Ukrainisch | Vietnamesisch | Weißrussisch",
        title5Description3: "Benötigen Sie Dolmetschdienste in einer anderen Sprache oder sprechen Sie einen besonderen Dialekt? Dann wenden Sie sich gerne persönlich an uns oder schicken uns eine Angebotsanfrage.",
        contactTitle: "Kontaktinformationen :",
        contactPerson: "Ahmed Abd El Aal",
        role: "INHABER & GESCHÄFTSFÜHRER",
        contactPersonImage: "/images/team/ahmed_abd_el_aal.jpg",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    tutoringPageData: [{
        title: "Nachhilfe",
        title1: "Ihre Vorteile:",
        title1Description1: "kostenlose Beratung",
        title1Description2: "individuell angepasster Nachhilfeunterricht",
        title1Description3: "erfahrene und qualifizierte Lehrkräfte",
        title1Description4: "sofort direkter persönlicher Kontakt zur Lehrkraft",
        title1Description5: "ständiger Austausch über den Lernfortschritt",
        title1Description6: "einfache Organisation",
        title2: "Unser Angebot:",
        title2Description1: "für Primarstufe, Sekundarstufe  I & II ",
        title2Description2: "Mathematik",
        title2Description3: "Physik",
        title2Description4: "Chemie",
        title2Description5: "Biologie",
        title2Description6: "Deutsch",
        title2Description7: "Englisch",
        title2Description8: "Französisch",
        title2Description9: "Spanisch",
        title2Description10: "Sachunterricht",
        title2Description11: "Geschichte",
        title2Description12: "Geografie",
        text1: "Wir sind davon überzeugt, dass Nachhilfe und Lernförderung nur dann gut funktionieren, wenn ein gegenseitiges Vertrauensverhältnis zwischen Eltern, SchülerInnen und LehrerInnen besteht. Aus diesem Grund arbeitet KAZA ausschließlich mit qualifizierten Lehrkräften zusammen, die über hohe soziale und interkulturelle Kompetenzen verfügen. Zusätzlich zu den oben genannten Fächern bieten wir in Rücksprache mit Ihnen gerne auch weitere Fächer an, für die Sie oder Ihr Kind Nachhilfeunterricht benötigt. ",
        text2: "Zu Beginn einer Förderung profitieren die Eltern bei uns von einer kostenlosen Beratung, woraufhin ein individueller Lernplan für Ihr Kind erstellt wird. Der Unterricht kann bei Ihnen zuhause oder in unseren modernen Unterrichtsräumen stattfinden. ",
        text3: "Unser Angebot richtet sich sowohl an selbstzahlende Privatkunden als auch an Leistungsempfänger, für die das Jobcenter oder das Sozialamt die Kosten übernehmen.",
        text4: "",
        title3: "Unsere Grundsätze für erfolgreiches Lernen:",
        title4: "NAH. ",
        title4Description: "Die Nachhilfestunden werden bei uns sowohl in Einzel- als auch Gruppenunterricht angeboten. Je nach Präferenz können die SchülerInnen entweder in vertrauter Umgebung zu Hause unterrichtet werden oder in den Räumlichkeiten unseres zentral gelegenen und gut erreichbaren Büros. Wir verfügen über zwei großzügig geschnittene Unterrichtsräume, die mit modernster Technik ausgestattet sind und Platz für bis zu zehn SchülerInnen bieten. Alternativ gibt es zudem die Möglichkeit, den Nachhilfeunterricht durch gut zugängliche Plattformen online durchzuführen. ",
        title5: "INDIVIDUELL. ",
        title5Description: "Jedes Kind hat unterschiedliche Ansprüche. Daher geben wir bei KAZA unser Bestes, genauestens auf die individuellen Bedürfnisse unserer SchülerInnen einzugehen. Sei es bei der Festlegung der regelmäßigen Unterrichtszeiten, der Unterrichtsform oder auch der Fächerzusammenstellung – für uns sind hohe Flexibilität und Individualität selbstverständlich. Zudem arbeiten wir erfolgsorientiert, um eine hohe Motivation bei unseren SchülerInnen zu erreichen und somit eine nachhaltige Entwicklung ihrer Lernerfolge anzustoßen.",
        title6: "INTERKULTURELL. ",
        title6Description: "Als etabliertes Übersetzungs- und Dolmetschbüro bringen wir sehr viel Erfahrung im Umgang Menschen aus den verschiedensten Kulturkreisen mit. Auf dieser Basis konnten wir in den vergangenen Jahren bereits das Vertrauen einer Vielzahl von Kunden gewinnen. Aus unserer Sicht ist der Bedarf an interkultureller Kompetenz auch im Bereich der Nachhilfe und Lernförderung hoch, wodurch eine gute Zusammenarbeit zwischen Eltern und LehrerInnen gefördert wird.",
        contactTitle: "Kontaktinformationen :",
        contactPerson: "Dana Al Khawam",
        role: "BÜROLEITERIN & KUNDENDIENST",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "nachhilfe@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    family: [{
        title: "Familienzusammenführung",
        titleImage: "",
        titleDescription1: "Als besonderes Angebot für unsere arabischen und internationalen Kunden bieten wir unsere Unterstützung beim Nachholen von engen Familienangehörigen nach Deutschland an. Im Rahmen der Familienzusammenführung können Ehegatten, minderjährige Kinder, oder in bestimmten Fällen auch andere Familienmitglieder nachgeholt werden. Damit soll bereits in Deutschland lebenden Einwanderern ermöglicht werden, wieder ihre Familieneinheit herzustellen.",
        titleDescription2: "Bei den zeitaufwendigen und komplizierten Verfahren zur Familienzusammenführung bieten wir Ihnen unsere Unterstützung an. Durch langjährige Erfahrung und Begleitung von Einwanderern und Visum-Antragstellern wissen wir genau, worauf es ankommt. Schritt für Schritt begleiten wir Sie durch alle bürokratischen und sprachlichen Hürden bis zur Fertigstellung des Antrags und der Ankunft der Familienangehörigen in Deutschland. ",
        title2: "Unser Angebot:",
        title2Description1: "Unterstützung bei den Verfahren zum Erhalt der nötigen Vollmachten für die Familienzusammenführung durch unsere Kooperation mit einem professionellen Notariatsbüro (Vollmacht zur Eheschließung, Vollmacht für die Vertretung bei Behörden und der syrischen Botschaft)",
        title2Description2: "Beschaffung und Beglaubigung der benötigten Dokumente für den Antrag zur Familienzusammenführung",
        title2Description3: "Terminbuchung bei der deutschen Botschaft",
        title2Description4: "Vorbereitung auf das Interview in der deutschen Botschaft",
        title2Description5: "Versand und Empfang aller angeforderten Dokumente",
        title2Description6: "Unterstützung beim Ausfüllen der Antragsformulare",
        title2Description7: "Hilfe bei der Einspruchserhebung gegen abgelehnte Anträge zur Familienzusammenführung in Zusammenarbeit mit einem erfahrenen Notariatsbüro in Berlin",
        title2Description8: "Buchung einer Autovermietung für den Transport zur deutschen Botschaft",
        title2Description9: "Überprüfung aller Dokumente für den Antrag auf Familienzusammenführung",
        title2Description10: "",
        title2Description11: "",
        title2Description12: "",
        text: "Es besteht die Möglichkeit, unser komplettes Unterstützungsangebot anzufordern oder aus den oben aufgeführten Dienstleistungen nur einige auszuwählen. Durch einen gesondert aufgesetzten Vertrag zwischen unserem Büro und Ihnen werden alle Schritte genau geregelt. Wir gehen dabei gerne auf Ihre individuellen Wünsche und Ansprüche ein.",
        contactTitle: "Kontaktinformationen :",
        contactPerson: "Ibraheim Al Mokdad",
        role: "SPRACHMITTLER",
        contactPersonImage: "/images/team/ibraheim_al_mokdad.jpg",
        contactPersonEmail: "f.nachzug@kaza-sprachdienste.de",
        contactPersonTel: "016099633317",
    }],
    documentPageData: [{
        title: "Urkundenbeschaffung",
        titleImage: "/images/documents.jpeg",
        titleDescription: "Mit unserem Unterstützungsangebot für arabische Kunden bieten wir neben dem Projekt der Familienzusammenführung auch an, Urkunden und Zertifikate aus dem Heimatland zu beschaffen. Der erste und wichtigste Schritt für viele rechtliche und administrative Verfahren in Deutschland ist die Beschaffung der Originaldokumente. Uns ist es wichtig, mit einem umfassenden Angebot konkret auf die Wünsche unserer Kunden einzugehen. ",
        title2: "Urkundenbeschaffung aus Syrien und Ägypten:",
        title2Description1: "Personenstandsurkunden (z.B. Familienbuch, Geburtsurkunde, Auszug aus dem Zivilregister, Auszug aus dem Familienregister, Sterbeurkunde)",
        title2Description2: "Standesamtliche Bescheinigungen (z.B. Bestätigung der Eheschließung, Eheurkunde, Scheidungsurkunde, Geburtsbescheinigung)",
        title2Description3: "Schulzeugnisse (9. bis 12. Klasse)",
        title2Description4: "Zeugnisse von Instituten und Universitäten (z.B. Abschlusszeugnis, Notenübersicht, Fächerübersicht)",
        title2Description5: "Arbeitszeugnis (für Studenten, Praktikanten und Angestellte)",
        title2Description6: "Polizeiliches Führungszeugnis",
        title2Description7: "und weitere auf Anfrage",
        text: "Zur Beschaffung der Dokumente arbeiten wir mit Notariatsbüros vor Ort zusammen. Anschließend können wir durch eine Vollmacht die Beglaubigung der Dokumente bei der deutschen Botschaft für Sie übernehmen.",
        title3: "Notarielle Vollmachten:",
        title3Description1: "Benötigen Sie eine Vollmacht zur Eheschließung oder zur Scheidung, eine Erbschaftsvollmacht oder eine Allgemeine Vollmacht?",
        title3Description2: "Wir bieten Ihnen unsere Unterstützung an, indem wir in Zusammenarbeit mit einem spezialisierten Notariatsbüro die für Sie passende Vollmacht finden. Die Vollmacht wird zu dem von Ihnen gewünschten Termin fristgerecht und entsprechend Ihrer Ansprüche ausgestellt.",
        contactTitle: "Kontaktinformationen :",
        contactPerson: "Dana Al Khawam",
        role: "BÜROLEITERIN & KUNDENDIENST",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    offerPageData: [{
        title_: "Angebotsanfrage",
        greeting_: "Anrede",
        Mrs_: "Frau",
        Mr_: "Herr",
        company_: "Firma",
        firstName_: "Vorname",
        lastName_: "Nachname",
        email_: "Ihre E-Mail-Adresse",
        phone_: "Telefon",
        city_: "Ort",
        street_: "Straße",
        hausNumber_: "Hausnummer",
        plz_: "PLZ",
        services_: "Angeforderte Dienstleistungen:",
        serviceLesson_: ["Nachhilfe"], // not used
        serviceFamily: ["Familienzusammenführung"],
        serviceCountry_: "Land",
        serviceCountryOption_: ["---Bitte wählen---", "Syrien", "Ägypten", "Libanon"],
        servciePrivateLesson_: "Nachhilfe",
        privatelessonOption_: ["---Bitte wählen---", "für Primarstufe, Sekundarstufe I & II", "Mathematik", "Physik", "Chemie", "Biologie", "Deutsch", "Englisch", "Französisch", "Spanisch", "Sachunterricht", "Geschichte", "Geografie"],
        serviceDocuments_: "Urkundenbeschaffung",
        serviceDocumentsOption_: ["---Bitte wählen---", "Personenstandsurkunden", "Schulzeugnisse", "Arbeitszeugnis", "andere Anfragen"],
        serviceWithTranslation_: "Mit Übersetzung",
        serviceWithAuthentication_: "Mit Legalisation der Deutschen Botschaft in Beirut",
        serviceWithAuthentication_2: "Mit Legalisation der Deutschen Botschaft in Kairo",
        servicesInterpretation_: ["---Bitte wählen---", "Übersetzen", "Dolmetschen"],
        translationsFrom_: "Ausgangssprache",
        translationsTo_: "Zielsprache",
        languageOption1_: ["---Bitte wählen---", "Afghanisch", "Afrikaans", "Albanisch", "Amharisch", "Arabisch", "Armenisch", "Aserbaidschanisch", "Bengali", "Bosnisch", "Bulgarisch", "Chinesisch", "Dänisch", "Dari", "Deutsch", "Englisch", "Estnisch", "Farsi", "Finnisch", "Französisch", "Georgisch", "Griechisch", "Hindi", "Indonesisch", "Italienisch", "Japanisch", "Kasachisch", "Koreanisch", "Kroatisch", "Kurdisch", "Lettisch", "Litauisch", "Makedonisch", "Montenegrinisch", "Niederländisch", "Norwegisch", "Paschtu", "Persisch", "Polnisch", "Portugiesisch", "Punjabi", "Rumänisch", "Russisch", "Schwedisch", "Serbisch", "Serbokroatisch", "Slowakisch", "Slowenisch", "Spanisch", "Thailändisch", "Türkisch", "Tschechisch", "Ungarisch", "Urdu", "Ukrainisch", "Vietnamesisch", "Weißrussisch"],
        languageOption2_: ["---Bitte wählen---", "Afghanisch", "Afrikaans", "Albanisch", "Amharisch", "Arabisch", "Armenisch", "Aserbaidschanisch", "Bengali", "Bosnisch", "Bulgarisch", "Chinesisch", "Dänisch", "Dari", "Deutsch", "Englisch", "Estnisch", "Farsi", "Finnisch", "Französisch", "Georgisch", "Griechisch", "Hindi", "Indonesisch", "Italienisch", "Japanisch", "Kasachisch", "Koreanisch", "Kroatisch", "Kurdisch", "Lettisch", "Litauisch", "Makedonisch", "Montenegrinisch", "Niederländisch", "Norwegisch", "Paschtu", "Persisch", "Polnisch", "Portugiesisch", "Punjabi", "Rumänisch", "Russisch", "Schwedisch", "Serbisch", "Serbokroatisch", "Slowakisch", "Slowenisch", "Spanisch", "Thailändisch", "Türkisch", "Tschechisch", "Ungarisch", "Urdu", "Ukrainisch", "Vietnamesisch", "Weißrussisch"],
        numberOfDocuments: "Anzahl",
        numberOfDocumentsOption: ["---Bitte wählen---", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        message: "Ihre Mitteilung",
        attachDocuments: "Dokumente anhängen",
        privacyPolicy: "Ich habe die Datenschutzerklärung gelesen und akzeptiere diese.",
        privacyPolicyDescription: "Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an info@kaza-sprachdienste.de widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer Datenschutzerklärung.",
        sendButton: "Angebotsanfrage abschicken",
    }],
    visionPageData: [{
        title: "Vision",
        text1: "KAZA Sprachdienste ist ein Sprachendienstleister für Übersetzungs- und Dolmetscherdienste und Zusatzleistungen wie Dokumentenbeschaffung und Hilfe beim Projekt der Familienzusammenführung für arabische Kunden. Angegliedert ist zudem eine eigene Nachhilfeschule mit einem großen Fächerangebot für SchülerInnen. ",
        text2: "Das Büro hat seinen Sitz in Leipzig und wurde im Oktober 2019 von Ahmed Abd El Aal nach langjährigem internationalem Einsatz als Übersetzer und Sprachlehrer gegründet. Mit der Gründung wurde das Ziel verfolgt, einen Raum zu schaffen, wo Integration von Sprachen und Kulturen ermöglicht wird. Durch die enge Kooperation mit weiteren ambitionierten und erfahrenen FachübersetzerInnen und DolmetscherInnen wurde diese Vision umgesetzt und Schritt für Schritt weiterentwickelt, sodass sich KAZA Sprachdienste heute zu einem etablierten Sprachendienstleister für Arabisch und viele weitere Sprachen geworden ist.",
        text3: "Unser primärer Fokus lag auf der Integration der arabischen Sprache und Kultur in das deutsche Umfeld und auf der Vermittlung der deutscher Sprache und Kultur an arabische und internationale Kunden. Aufgrund des großen Bedürfnisses an interkultureller Vermittlung und einer stetigen Erweiterung unseres Teams durch kompetente FachübersetzerInnen, DolmetscherInnen, LektorInnen sowie Sprachvermittler und LehrerInnen konnten wir unser Angebot in den letzten Jahren auf zahlreiche weitere Sprachen und Kulturen ausweiten. Heute umfasst unser Angebot fast alle Sprachen und Kulturkreise.",
        text6: "",
        title1: "Unsere Vision der Integration von Sprachen und Kulturen baut auf drei Säulen auf:",
        title1Description1: "Überbrückung sprachlicher Barrieren durch Übersetzungsdienstleistungen",
        title1Description2: "Schaffung von überkulturellem Vertrauen und Verständnis durch Dolmetscherdienste und Sprachvermittlung",
        title1Description3: "Unterstützung unserer Kunden bei der Ankunft und dem Leben in Deutschland durch unser Programm der Familienzusammenführung und unser umfassendes Begleitungs- und Beratungsangebot",
        text4: "Von unserer langjährigen interkulturellen Erfahrung als Sprachdienstleister profitieren heute unsere NachhilfeschülerInnen, denen wir neben Verständnis für die jeweilige fachliche Materie auch ein großes Maß an interkulturellem Verständnis und Kommunikationsbereitschaft mitgeben.",
        text5: "Kunden aus allen Bereichen der Gesellschaft schenken uns ihr Vertrauen. Privatkunden aus vielen verschiedenen Ländern und kulturellen Hintergründen ebenso wie öffentliche Institutionen der Bundesrepublik Deutschland und internationale Unternehmen greifen auf unsere Leistungen zurück. Unser Anliegen ist der direkte und verlässliche Kundenkontakt, um unsere Dienstleistungen individuell auf den konkreten Bedarf abzustimmen.",

    }]
}]
/* English version ******************************************************************************************** */
export const webDataSourceEN = [{
    lan: "En",
    navbarData: [{
        start: "Home",
        translation: "Translation",
        interpreting: "Interpreting",
        tutoring: "Private lessons ",
        other: "Extra services",
        family: "Family reunion",
        document: "Acquisition of documents",
        offer: "Request for quote",
        blog: "Blog",
        about: "About us",
        team: "Team",
        vision: "Vision",
        logo: "/images/logo.png",
    }],
    homePageData: [{
        introduction: [{
            title1: "KAZA Sprachdienste ",
            title2: "Your translation partner in Leipzig",
            title3: "Our qualities:",
            list1: "Many years of experience",
            list2: "Specialist expertise in many different fields",
            list3: "Comprehensive support step by step",
            list4: "Individual consultation",
            text1: "KAZA Sprachdienste provides different language services such as translation and interpreting in many different languages as well as special help to our Arabic clients in proceeding with their request of family reunion. Last but not least, we offer private lessons/tutoring for school students. ",
            text2: "The translation office is located in the heart of the city of Leipzig and was founded in 2019 by today's managing director Ahmed Abd El Aal. Our vision is to create a place that makes the integration of languages, cultures and education possible. In close cooperation with many ambitious and experienced translators, interpreters and teachers we have developed to become not only a renowned translation office but to also provide/offer private lessons to students in many different subjects.",
            text3: "",
            text4: "Your team at KAZA Sprachdienste is looking forward to working with you and supporting you!",
            ownerImage: "/images/hero2.png",
            ownername: "Ahmed Abd El Aal",
            description: "INHABER & GESCHÄFTSFÜHRER",
            ownerLogo: "/images/bdue.png",
            openTimeTitle1: "Contact us",
            telephone: "0341 / 860 872 73",
            openTime: "Mo - Fr 09:30 - 18:30",
            hotLineTitle: "Hotline",
            hotLineNumber: " 0160 / 9963 33 17",
            hotLineDescription: "Outside office hours, weekends and national holidays",
            address: "Hans-Poeche-Straße 5",
            plz: "04103 Leipzig",
            email: "info@kaza-sprachdienste.de",
            teamImage: "/images/hero1.jpeg",
        }],
        ourServices: [{
            servicesTitle: "Our services",
            serviceName1: "Certified translation",
            serviceDescription1: "Your official documents are in good hands with our specialized and professional translators who provide a reliable and double-checked translation for all kind of texts. You can be sure to always get your translation on time with us.",
            serviceName2: "Interpreting services",
            serviceDescription2: "We offer interpreting services in many languages to private clients as well as companies and institutions. For your special purpose, court hearings, conferences, or any other occasion, you will find the perfect interpreter through us.",
            serviceName3: "Private lessons",
            serviceDescription3: "Our professional teachers provide private lessons to pupils and students for variety of different subjects (languages and science).",
            serviceName4: "Family reunion",
            serviceDescription4: "Our comprehensive services include support during all proceedings of legal Family Reunion until the arrival of your partner of family member in Germany.",
            serviceName5: "Acquisition of documents",
            serviceDescription5: "In cooperation with a specialized civil notary we issue all needed powers of attorney in Egypt and Syria to acquire your official documents. In addition, we provide support in all formal steps of their legalization With the relevant authorities.",
        }],
        wisdom: "Languages are the gateway to the world",
        wisdomAuthor: "W.V. HUMBOLDT",
        offerText1: "Are you interested in our language services?",
        offerText2: "Contact us or use our request form to get your personalized quote here and now!",
        offerButton: "Request a quote",
        socialMedia: "Find us on social media ",
        phone: "Phone:",
        email: "Email: "

    }],
    translationPageData: [{
        title: "Translation",
        titleImage: "/images/uebersetzungsbuero.jpg",
        title1: "Certified translations",
        title1Description: "Our network of experienced and specialized translators provides a professional and reliable translation for all your official documents, certificates, brochures or any other texts. We work according to German and international standards.",
        title2: "Specialized translations",
        title2Description1: "We are a competent partner for your translation needs with the expertise of our translators in many different fields. Whether you need a technical translation for your company, the translation of a medical report, a court decision, a contract of sale or any other text type - we can provide you with a competent translator.",
        title2Description2: "",
        title3: "Quality check",
        title3Description1: "Our translations meet the highest quality standards as they are double-checked by specialised and officially appointed translators and afterwards signed and certified by stamp. You can be sure to always get your translation on time with us",
        title3Description2: "",
        title3Description3: "",
        title4: "Languages",
        title4Description1: "Whether you are looking for a translation for a specific language or your company needs a corporate translation for all your texts into several languages. Our network of professional translators covers more than 30 languages. ",
        title4Description2: "We fulfil any translation request for the following languages: ",
        title4Description3: "Afghan | Afrikaans | Albanian | Amharic | Arabic | Armenian | Azerbaijani | Belarussian | Bengali | Bosnian | Bulgarian | Chinese | Croatian | Czech | Danish | Dari | Dutch | English | Estonian | Farsi | Finnish | French | Georgian | German | Greek | Hindi | Hungarian | Indonesian | Italian | Japanese | Kazakh | Korean | Kurdish | Lettish | Lithuanian | Macedonian | Montenegrin | Norwegian | Pashtu | Persian | Polish | Portuguese | Punjabi | Romanian | Russian | Serbian | Serbo-Croatian | Slovakian | Slovenian | Spanish | Swedish | Thai | Turkish | Urdu | Ukrainian | Vietnamese",
        title4Description4: "If you need translation into or from another language than those mentioned above, please let us know or just request a quote",
        contactTitle: "Contact information:",
        contactPerson: "Dana Al Khawam",
        role: "OFFICE MANAGER & CUSTOMER SERVICE",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
        
    }],
    interpretingPageData: [{
        title: "Interpreting",
        titleImage: "/images/dolmetscher.jpg",
        title1: "You need support for your appointment with an office or the doctor in another language?",
        title2: "You need a publicly appointed and sworn interpreter?",
        title3: "You are looking for a simultaneous or consecutive interpreting service for a meeting or a conference?",
        title4: "You seek language mediation for your contract negotiations?",
        text1: "You can find all this and much more with us. We provide professional interpreting services for every occasion in many different languages. Our experienced interpreters are at your side in court proceedings, company events, conferences, interviews, negotiations and many other occasions. ",
        text2: "We provide you with an exact linguistic and cultural mediation of your content and ensure your conversation takes place smoothly. All our interpreters are professionally trained. Thanks to their linguistic and intercultural expertise your conversations can proceed without complications and whilst being understandable for all participants.",
        text3: "Our interpreting services are available for private persons as well as public institutions and international companies, as our interpreters cover many different branches and can adapt perfectly to every conversational situation.",
        text4: "Personal contact to our clients is our top priority in order to ensure the highest quality for your request. We respond to all your concerns and ideas and provide you with an appropriate and experienced interpreter.",
        text5: "",
        title5: "Languages",
        title5Description1: "We are specialized in many different languages for interpreting services appropriate for all kinds of occasions. Interpreting in the following languages is available at any time: ",
        title5Description2: "Afghan | Afrikaans | Albanian | Amharic | Arabic | Armenian | Azerbaijani | Belarussian | Bengali | Bosnian | Bulgarian | Chinese | Croatian | Czech | Danish | Dari | Dutch | English | Estonian | Farsi | Finnish | French | Georgian | German | Greek | Hindi | Hungarian | Indonesian | Italian | Japanese | Kazakh | Korean | Kurdish | Lettish | Lithuanian | Macedonian | Montenegrin | Norwegian | Pashtu | Persian | Polish | Portuguese | Punjabi | Romanian | Russian | Serbian | Serbo-Croatian | Slovakian | Slovenian | Spanish | Swedish | Thai | Turkish | Urdu | Ukrainian | Vietnamese",
        title5Description3: "If you need interpreting services for another language than those mentioned above, please let us know or just request a quote.",
        contactTitle: "Contact information:",
        contactPerson: "Ahmed Abd El Aal",
        role: "OWNER & MANGING DIRECTOR",
        contactPersonImage: "/images/team/ahmed_abd_el_aal.jpg",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    tutoringPageData: [{
        title: "Private lessons",
        title1: "Your benefits:",
        title1Description1: "free (initial) consultation ",
        title1Description2: "individual and customized private lessons",
        title1Description3: "experienced and qualified teachers",
        title1Description4: "immediate personal contact between teacher and parents",
        title1Description5: "continual/regular exchange about the pupil’s progress",
        title1Description6: "simple organisation",
        title2: "Our offer:",
        title2Description1: "for primary and secondary level ",
        title2Description2: "Mathematics",
        title2Description3: "Physics",
        title2Description4: "Chemistry",
        title2Description5: "Biology",
        title2Description6: "German",
        title2Description7: "English",
        title2Description8: "French",
        title2Description9: "Spanish",
        title2Description10: "",
        title2Description11: "History",
        title2Description12: "Geography",
        text1: "We are convinced that private lessons and learning support depend on a good relation and trusting cooperation between parents, students and teachers. For this reason, KAZA only works with highly qualified teachers with excellent social and intercultural skills. Beside the above-mentioned subjects, we also provide private lessons for any other subject that you or your child request.",
        text2: "At the beginning of the teaching process we offer a free consultation session for . After that we develop an individual study plan for your child together. You can decide whether the lessons take place in your home or in our modern classrooms. ",
        text3: "Both paying private persons/clients and people receiving financial support from the Jobcenter or social assistance can make use of our private lessons.",
        text4: "",
        title3: "Our principles for successful learning:",
        title4: "CONFIDENT. ",
        title4Description: "The tuition can take place in one-to-one sessions or as a group. According to your preference, the teacher can come to your home to create a more comfortable atmosphere, or the lessons can take place in our spacious classrooms. The central location of our office allows you to reach it very easily by bus and train. We have two modern furnished and spacious classrooms with space for up to ten students at a time. Alternatively, we have the possibility of online teaching.  ",
        title5: "INDIVIDUAL. ",
        title5Description: "Each child has its own needs. For this reason, we try to respond to every individual need and expectation of our students. From the right choice of time, place and type of lesson to matching the required subjects - flexibility and individuality are our highest priority. The concept of the lessons is always success-oriented in order to motivate the students and achieve our goal of sustainable learning development and success.",
        title6: "INTERCULTURAL. ",
        title6Description: "As a renowned translation and language office we already have a lot of experience in intercultural dialogue. On this basis we have gained many new clients’ trust in the last couple of years. From our point of view, there is also a vast need of intercultural competence in the field of private lessons and learning support, in order to create and encourage a trustful cooperation between parents and teachers. ",
        contactTitle: "Contact information:",
        contactPerson: "Dana Al Khawam",
        role: "OFFICE MANAGER & CUSTOMER SERVICE",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "nachhilfe@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    family: [{
        title: "Family Reunion",
        titleImage: "",
        titleDescription1: "As a special offer for our clients from Arab countries and international clients we offer support with all formalities of a request for family reunion in Germany. Third-country nationals residing in Germany have the possibility to bring over their spouse, minor children, or other family members. The special family reunion visa can be applied for by non-EU nationals who want to join their family member in Germany for the purpose of reunification of families.",
        titleDescription2: "The procedure of the request for family reunion is one of the most difficult and time-consuming procedures in Germany. We provide support with all formalities and invest all our knowledge and experience while guiding you through the procedure step by step until the acquisition of the needed documents and the arrival of your family member in Germany. ",
        title2: "We provide the following services:",
        title2Description1: "Support to get the necessary/required powers of attorney for the procedure of family reunion, in cooperation with a professional civil notary (power of attorney for the purpose of marriage, procurations for representation in governmental authorities and in the Syrian embassy)",
        title2Description2: "Acquisition and legalization of required documents for the purpose of family reunion",
        title2Description3: "Booking an appointment at the German embassy ",
        title2Description4: "Preparation for the interview at the German embassy",
        title2Description5: "Dispatch and receipt of requested documents",
        title2Description6: "Support in filling in the request forms ",
        title2Description7: "Support in raising an appeal against rejected requests of family reunion, in cooperation with a specialized civil notary in Berlin",
        title2Description8: "Booking of car rental services to get you to the embassy ",
        title2Description9: "Review of all required documents for the procedure of family reunion",
        title2Description10: "",
        title2Description11: "",
        title2Description12: "",
        text: "You are free to choose the whole package or just some of the services mentioned above. The services that you choose will be agreed on by contract. We are at your disposition and respond to your specific demands. ",
        contactTitle: "Contact information:",
        contactPerson: "Ibraheim Al Mokdad",
        role: "LANGUAGE MEDIATOR",
        contactPersonImage: "/images/team/ibraheim_al_mokdad.jpg",
        contactPersonEmail: "f.nachzug@kaza-sprachdienste.de",
        contactPersonTel: "016099633317",
    }],
    documentPageData: [{
        title: "Acquisition of documents",
        titleImage: "/images/documents.jpeg",
        titleDescription: "Our comprehensive offer for Arabic clients starts from the very beginning. We are there by your side and provide support for your concern from the very start to its final step. One of the first and most important steps in every official or legal concern in Germany is the acquisition of your original documents from your home country. We are committed to adapting individually to our clients’ wishes and providing trustful services.",
        title2: "Acquisition of documents from Syria and Egypt:",
        title2Description1: "Personal documents (e.g. family record card, birth certificate, statement from the civil register, statement from the family register, death certificate)",
        title2Description2: "Administrative certificates (e.g. confirmation of marriage, marriage certificate, divorce certificate, confirmation of birth)",
        title2Description3: "School certificates (grade 9 to 12)",
        title2Description4: "Certificates of institutes and universities (e.g. diploma, transcript of records, summary of subjects)",
        title2Description5: "Job references (for students and employees)",
        title2Description6: "Criminal record",
        title2Description7: "and more",
        text: "In cooperation with a professional civil notary, we provide your original documents from Syria and Egypt. By an official notarial power of attorney, we can then request the official legalization of your documents from the German embassy.",
        title3: "Notarial powers of attorney:",
        title3Description1: "Do you need a general power of attorney or a special power of attorney for the purpose of marriage, divorce or matters of inheritance?",
        title3Description2: "Our services provide support in finding the suitable power of attorney to fit your concern. In cooperation with a specialized and professional civil notary we issue for you the power of attorney for you that you need and deliver it in time.",
        contactTitle: "Contact information:",
        contactPerson: "Dana Al Khawam",
        role: "OFFICE MANAGER & CUSTOMER SERVICE",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    offerPageData: [{
        title_: "Request for Quotation",
        greeting_: "Title",
        Mrs_: "Mrs.",
        Mr_: "Mr.",
        company_: "Company",
        firstName_: "First Name",
        lastName_: "Last Name",
        email_: "Your email address",
        phone_: "Phone",
        city_: "City",
        street_: "Street",
        hausNumber_: "Haus Number",
        plz_: "Zip/postal code",
        services_: "Requested Services:",
        serviceLesson_: ["Private Lesson"],
        serviceFamily: ["Family reunification"],
        serviceCountry_: "country",
        serviceCountryOption_: ["---Please choose---", "Syria", "Egypt", "Lebanon"],
        servciePrivateLesson_: "Private Lesson",
        privatelessonOption_: ["---Please choose---", "for primary and secondary evel", "Mathematics", "Physics", "Chemistry", "Biologie", "German", "English", "French", "Spanish", "History", "Geography",],
        serviceDocuments_: "Acquisition of documents",
        serviceDocumentsOption_: ["---Please choose---", "Personenstandsurkunden", "Standesamtliche Bescheinigungen", "Schulzeugnisse", "Zeugnisse", "Arbeitszeugnis", "weitere auf Anfrage"],
        serviceWithTranslation_: "With Translation",
        serviceWithAuthentication_: "With legalization of the German Embassy in Beirut",
        serviceWithAuthentication_2: "With legalization of the German Embassy in Cairo",
        servicesInterpretation_: ["---please choose---", "Translation", "Interpreting"],
        translationsFrom_: "Translate from",
        translationsTo_: "Translate to",
        languageOption1_: ["---Please choose---", "Afghan", "Afrikaans", "Albanian", "Amharic", "Arabic", "Armenian", "Azerbaijani", "Bengali", "Bosnian", "Bulgarian", "Chinese", "Danish", "Dari ", "German", "English", "Estonian", "Farsi", "Finnish", "French", "Georgian", "Greek", "Hindi", "Indonesian", "Italian", "Japanese", "Kazakh", "Korean", "Croatian", "Kurdish", "Latvian", "Lithuanian", "Macedonian", "Montenegrin", "Dutch", "Norwegian", "Pashto", "Persian", "Polish", "Portuguese", "Punjabi", "Romanian", "Russian", "Swedish", "Serbian", "Serbo-Croatian", "Slovak", "Slovene", "Spanish", "Thai", "Turkish", "Czech", "Hungarian", "Urdu", "Ukrainian", "Vietnamese", "Belarusian"],
        languageOption2_: ["---Please choose---", "Afghan", "Afrikaans", "Albanian", "Amharic", "Arabic", "Armenian", "Azerbaijani", "Bengali", "Bosnian", "Bulgarian", "Chinese", "Danish", "Dari ", "German", "English", "Estonian", "Farsi", "Finnish", "French", "Georgian", "Greek", "Hindi", "Indonesian", "Italian", "Japanese", "Kazakh", "Korean", "Croatian", "Kurdish", "Latvian", "Lithuanian", "Macedonian", "Montenegrin", "Dutch", "Norwegian", "Pashto", "Persian", "Polish", "Portuguese", "Punjabi", "Romanian", "Russian", "Swedish", "Serbian", "Serbo-Croatian", "Slovak", "Slovene", "Spanish", "Thai", "Turkish", "Czech", "Hungarian", "Urdu", "Ukrainian", "Vietnamese", "Belarusian"],
        numberOfDocuments: "Quantity",
        numberOfDocumentsOption: ["---Please choose---", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        message: "Your Message",
        attachDocuments: "Attach documents",
        privacyPolicy: "I have read and accept the Privacy Policy",
        privacyPolicyDescription: "I agree that my details from the contact form will be collected and processed to answer my request. The data will be deleted after your request has been processed. Note: You can revoke your consent at any time for the future by sending an email to info@kaza-sprachdienste.de. Detailed information on handling user data can be found in our data protection declaration.",
        sendButton: "Send my request",
    }],
    visionPageData: [{
        title: "Vision",
        text1: "KAZA Sprachdienste is an office for language services providing translation and interpreting services as well as extra services including the acquisition of documents and support in the application for family reunion for Arab clients. Affiliated to the office is a program for private lessons to students for many different subjects.",
        text2: "The office is situated in the heart of Leipzig and was founded in October 2019 by Ahmed Abd El Aal  who looks back on many years of experience as an international translator and language teacher. With the foundation of KAZA Sprachdienste he pursues the aim to create a place that makes the integration of languages and cultures possible. In cooperation with other ambitious and experienced translators one step after the other was taken to achieve this aim. Consequently, KAZA Sprachdienste has continually developed and is today considered a renowned office for language services for Arabic and many other languages.",
        text3: "Our first focus was on the integration of the Arabic language and culture into the German environment as well as on the teaching of the German language and culture to Arab and international clients. Due to the growing need of intercultural mediation and thanks to the addition of new competent translators, interpreters, editors, mediators, and teachers to our team in the past few years we could expand to offer services in many more languages and cultures. Today KAZA is offering services for almost every language and culture.",
        text6: "",
        title1: "Our vision of the integration of languages and cultures is based on three cornerstones:",
        title1Description1: "Overcome language barriers with the help of translation services ",
        title1Description2: "Create cross-cultural trust and understanding with the help of interpretation and cultural mediation",
        title1Description3: "Support our clients in arriving and living in Germany with the help of our program for family reunion  and our comprehensive advisory service",
        text4: "Our students of private lessons benefit from our long-standing intercultural experience as we pass on not only the understanding of the specific subject but also the ability of intercultural comprehension and communication.",
        text5: "Clients from all sectors of society place their trust in us. Private clients from all over the world and with many different backgrounds as well as public institutions in Germany and international companies make use of our services. We place special emphasis on a direct and reliable contact with our clients in order to respond to your concerns individually. ",

    }]
}]

/* Arabic version ************************************************************************************* */
export const webDataSourceAR = [{
    lan: "Ar",
    navbarData: [{
        start: "الصفحة الرئيسية",
        translation: "الترجمة التحريرية",
        interpreting: "الترجمة الشفوية",
        tutoring: "دروس التقوية",
        other: "خدمات أخرى",
        family: "إجراءات لم الشمل",
        document: "استخراج الأوراق من سوريا ومصر",
        offer: "طلب عرض",
        blog: "مقالات",
        about: "من نحن",
        team: "فريقنا",
        vision: "رؤيتنا",
        logo: "/images/logo.png",
    }],
    homePageData: [{
        introduction: [{
            title1: "KAZA Sprachdienste",
            title2: "خدمات لغوية وتعليمية في لايبزيغ",
            title3: "ما يميزنا عن غيرنا:",
            list1: "سنوات عديدة من الخبرة",
            list2: "الخبرة في مختلف المجالات",
            list3: "الدعم الشامل خطوة بخطوة",
            list4: "المشورة الفردية",
            text1: "نقدم لكم في مكتبنا خدمات لغوية منها الترجمة المعتمدة والترجمة الشفوية وأيضاً خدمات الدروس الخاصة لطلاب المدارس كما نقدم لعملائنا العرب المساعدة في كل المعاملات المتعلقة بفيزة لم الشمل واستخراج الأوراق الأصلية.",
            text2: " يقع مكتبنا في مركز مدينة لايبزيغ تم تأسيسه عام 2019 من قبل المدير العام أحمد عبد العال.",
            text3: "من أجل تحقيق التفاهم اللغوي والإندماج الثقافي بين المجتمعات قمنا بالتعاون مع المترجمين المؤهلين والمدرسين المختصين لنقدم لكم الترجمة والمساعدة اللغوية في عدة مجالات وبلغات متعددة .",
            text4: "خدماتنا اللغوية والتعليمية طريقكم للنجاح ونحن دائما بخدمتكم!",
            ownerImage: "/images/hero2.png",
            ownername: "أ. أحمد عبد العال",
            description: "المؤسس والمالك",
            ownerLogo: "/images/bdue.png",
            openTimeTitle1: "يمكنكم الوصول إلينا على",
            telephone: "0341 / 860 872 73",
            openTime: "Mo - Fr 09:30 - 18:30",
            hotLineTitle: "الخط الساخن",
            hotLineNumber: " 0160 / 9963 33 17",
            hotLineDescription: "خارج ساعات العمل ، في عطلات نهاية الأسبوع والعطلات الرسمية",
            address: "Hans-Poeche-Straße 5",
            plz: "04103 Leipzig",
            email: "info@kaza-sprachdienste.de",
            teamImage: "/images/hero1.jpeg",
        }],
        ourServices: [{
            servicesTitle: "خدماتنا",
            serviceName1: "الترجمة التحريرية:",
            serviceDescription1: "نقدم ترجمة جميع الوثائق الرسمية والنصوص التخصصية وفق الوقت المحدد والبقاءعلى تواصل مع العميل أثناء الترجمة والتدقيق والإستعانة بمترجمين متخصصين لكل اللغات.",
            serviceName2: "الترجمة الشفوية:",
            serviceDescription2: "نضمن لكم الفهم المتبادل والحديث بوضوح من خلال خدمات المترجمين المحترفين للقيام بالترجمة الفورية خلال الاجتماعات والجلسات في كل المجالات القانونية والتعليمية والطبية والإجتماعية وغيرها.",
            serviceName3: "دروس التقوية:",
            serviceDescription3: "نوفر لكم الخدمات التعليمية من خلال تقديم دروس التقوية في أغلب المواد الدراسية مثل اللغات والرياضيات والعلوم لطلاب المرحلة الإبتدائية والثانوية، وذلك من قبل مدرسين محترفين ذوي خبرة طويلة في التدريس.",
            serviceName4: "إجراءات لم الشمل:",
            serviceDescription4: "نقدم المساعدة في جميع إجراءات لم الشمل حتى وصول الزوج(ة) إلى ألمانيا، والمحافظة على التواصل المباشر والمستمر مع العملاء ومتابعة كافة الإجراءات.",
            serviceName5: "استخراج الأوراق من سوريا ومصر:",
            serviceDescription5: "هل أنت بحاجة لاستخراج أوراقك الثبوتية أو شهاداتك الدراسية بهدف الإقامة او متابعة الدراسة أو العمل في ألمانيا؟ نحن نقدم هذه الخدمة باحترافيه و دقة، ونوفر لكم مساعدة في كل الخطوات المتعلقة بالتوكيل وتصديق الأوراق وذلك بالتعاون مع كاتب العدل.",
        }],
        wisdom: "اللغة هي مفتاح العالم",
        wisdomAuthor: "W.V. HUMBOLDT",
        offerText1: "هل أنت مهتم بخدماتنا ؟",
        offerText2: "إذاً املأ استمارة الطلب الخاصة بنا واحصل على عرض سعر فوراً",
        offerButton: "طلب عرض سعر",
        socialMedia: "شبكات التواصل الاجتماعي الخاصة بنا",
        phone: "Tel.:",
        email: "E-Mail: "
    }],
    translationPageData: [{
        title: "الترجمة التحريرية ",
        titleImage: "/images/uebersetzungsbuero.jpg",
        title1: "الترجمة المعتمدة",
        title1Description: "نقدم لكم ترجمة معتمدة لكل الوثائق القانونية المهمة مثل شهادة الميلاد وعقد الزواج وشهادة مهنية أو دراسية وغيرها. مع نخبة من المترجمين المؤهلين والمحترفين نقوم بترجمة وثائقكم ومستنداتكم الرسمية بكل دقة وأمانة. لدينا خبرة طويلة في الترجمة المحلفة لجميع أنواع المستندات كما لدينا المعرفة بالإجراءات البيروقراطية لتقديم ترجمة صحيحة ومطابقة للشروط المطلوبة في ألمانيا.",
        title2: "الترجمة  المتخصصة",
        title2Description1: "مع نخبة من المترجمين المؤهلين نقدم لكم خدمة الترجمة المتخصصة في جميع المجالات وكل أنواع المستندات والنصوص. إذا كنت من الباحثين أو من الطلاب او من الشركات والمؤسسات ومهما كان طلبكم نحن بخدمتكم حتى نقوم بترجمة احترافية ودقيقة. ",
        title2Description2: "تشمل خدماتنا كل من الترجمة الطبية والترجمة القانونية والترجمة العلمية والترجمة التقنية وحتى الترجمة الأدبية وترجمة النصوص الإعلامية. جميع المترجمين لديهم خبرة وكفاءة عالية في تخصص معين ونحن نختار المترجم المناسب لطلبكم.",
        title3: "ضمان الجودة العالية",
        title3Description1: "أولويتنا القصوى هي الجودة العالية لجميع خدماتنا اللغوية ولضمان هذه الجودة نبقى على الإتصال المباشر مع العميل إلى أن يتم تسليمه الترجمة. نتبع مبادئ وأسس دقيقة لنضمن لكم ترجمة صحيحة ومرضية بجودة عالية.",
        title3Description2: "بعد التحقق من صحة واكتمال الترجمة من قِبل المترجم يتم اعتماد جميع الوثائق والنصوص بالختم والتوقيع . التسليم يكون دائماً بالوقت المحدد مسبقا.",
        title3Description3: "",
        title4: "قائمة اللغات",
        title4Description1: "هل أنت بحاجة لترجمة من أو إلى لغة معينة أو لديك عدة وثائق أصلية خطية وتريد ترجمتها من قِبل مترجم متخصص؟ نحن دائما بخدمتك ونوفر لك ترجمة بدقة عالية لجميع اللغات وذلك من خلال التعاون مع نخبة من المترجمين المحلفين والمتخصصين في هذا المجال.",
        title4Description2: "نقدم الترجمة من و إلى اللغات التالية:",
        title4Description3: "أذريجيانية – اردو – أرمنية – إسبانية – أستونية – أفريكيانية – أفغانية – ألبانية – ألمانية – أمهرية – إنجليزية – أندونيسية – أوكرانية – إيطالية – برتغالة – بشتونية – بلغارية – بنجابي – بنغالي – بوسنية – بولندية – بيلاروسية – تايلاندية – تركية – تشيكية – جورجية – داري – دنماركية – روسية - رومانية – سلوفاكية – سلوفينية – سويدية – صربوكرواتية – صربية – صينية – عربية – فارسي – فرنسية – فنلندية – فيتنامية – كازاخية – كردية – كرواتية – كورية – لاتفية – ليتوانية – مقدونية – منتنجرية – نرويجية – هندية – هنغالرية – هولندية -  يابانية - يونانية",
        title4Description4: "في حال الحاجة إلى ترجمة من أو إلى لغة غير مذكورة أعلاه بإمكانك التواصل معنا هاتفياً أو عبرالبريد الإلكتروني أو بإرسال طلب عرض سعر وسيتم التواصل معكم بأسرع وقت ممكن! ونحن سعداء بخدمتكم دائماً",
        contactTitle: "معلومات الإتصال:",
        contactPerson: " دانا الخوام ",
        role: "مديرة المكتب والمسؤولة عن خدمة العملاء",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    interpretingPageData: [{
        title: "الترجمة الشفوية",
        titleImage: "/images/dolmetscher.jpg",
        title1: "هل تحتاج لمساعدة لغوية عند الطبيب أو في دائرة حكومية؟",
        title2: "هل تبحث عن مترجم شفوي معتمد لمناسبة رسمية أو قانونية؟",
        title3: "هل ترغب بالترجمة الفورية المحترفة في مؤتمر او إجتماع عمل؟",
        title4: "هل تحتاج لدعم لغوي في المفاوضات عند توقيع عقدد؟ ",
        text1: "مكتبنا يوفر لكم خدمة الترجمة الشفوية من خلال مجموعة من المترجمين المؤهلين لجلسات المحكمة، والإجتماعات، والمؤتمرات، والمقابلات، والمفاوضات، ونقدم لكم خدمة المرافقة إلى المعارض التجارية والثقافية وغيرها.",
        text2: "كما نضمن لكم الكفاءة والدقة في الترجمة الشفوية وتحقيق التفاهم اللغوي المتبادل لكي تسير محادثاتكم بسهولة. ",
        text3: "جميع المترجمين لدينا من حاملي الشهادات ولديهم خبرة طويلة في مجال الترجمة الشفوية للغات متعددة وفي مجالات مختلفة.  ",
        text4: "نقدم لكم خدمة الترجمة الشفوية لجميع العملاء أفراد, شركات,مؤسسات ومكاتب رسمية ودولية في مختلف المجالات وللغات متعددة.",
        text5: "أولوياتنا هي الدقة والجودة العالية لجميع خدماتنا اللغوية ولضمان هذه الجودة نبقى على اتصال مباشر مع العميل لنقوم باختيار المترجم المناسب له حسب رغبته. ",
        title5: "قائمة اللغات",
        title5Description1: "نوفر لكم خدمة الترجمة الشفوية للغات ولهجات عديدة منها: ",
        title5Description2: "أذريجيانية – اردو – أرمنية – إسبانية – أستونية – أفريكيانية – أفغانية – ألبانية – ألمانية – أمهرية – إنجليزية – أندونيسية – أوكرانية – إيطالية – برتغالة – بشتونية – بلغارية – بنجابي – بنغالي – بوسنية – بولندية – بيلاروسية – تايلاندية – تركية – تشيكية – جورجية – داري – دنماركية – روسية - رومانية – سلوفاكية – سلوفينية – سويدية – صربوكرواتية – صربية – صينية – عربية – فارسي – فرنسية – فنلندية – فيتنامية – كازاخية – كردية – كرواتية – كورية – لاتفية – ليتوانية – مقدونية – منتنجرية – نرويجية – هندية – هنغالرية – هولندية -  يابانية - يونانية",
        title5Description3: "في حال الحاجة لترجمة من و إلى لغة أو لهجة غير مذكورة أعلاه بإمكانك التواصل معنا هاتفياً أو عبرالبريد الإلكتروني ونحن بخدمتكم دائماً.",
        contactTitle: "معلومات الإتصال:",
        contactPerson: "الأستاذ أحمد عبد العال",
        role: "المالك والمدير العام",
        contactPersonImage: "/images/team/ahmed_abd_el_aal.jpg",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    tutoringPageData: [{
        title: "دروس التقوية",
        title1: "بالتعاون مع مدينة لايبزيغ والجوب سنتر يقوم مكتبنا بتقديم خدمة دروس التقوية ويشمل عرضنا الخدمات التالية:",
        title1Description1: "الإستشارة التعليمية المجانية",
        title1Description2: "دروس التقوية حسب حاجة الطالب",
        title1Description3: "مدرسين مؤهلين ومختصين",
        title1Description4: "التواصل الدائم بين المدرسين والأهالي",
        title1Description5: "إبلاغ الأهل عن تقدم الطالب في دروسه ووضعه الدراسي",
        title1Description6: "مرونة في تنظيم مواعيد الدروس",
        title2: "كما يقدم لكم مكتبنا دروس التقوية لجميع المستويات التعليمية في المواد التالية:",
        title2Description1: "الرياضيات",
        title2Description2: "الفيزياء",
        title2Description3: "الكيمياء",
        title2Description4: "علم الأحياء",
        title2Description5: "اللغة الألمانية",
        title2Description6: "اللغة الإنجليزية",
        title2Description7: "اللغة الفرنسية",
        title2Description8: "اللغة الإسبانية",
        title2Description9: "التربية الاجتماعية",
        title2Description10: "التاريخ",
        title2Description11: "الجغرافيا",
        title2Description12: "وغيرها",
        text1: "لأن الثقة بين الأهل والمدرس هي الأساس لدعم التعليم الناجح، يتواصل المدرسون بشكل مستمر مع الطالب والأهل. ويتم إبلاغ الأهل وتقديم تقرير كامل لهم عن وضع الطالب العلمي ومدى تحسنه في دروسه بشكل دائم.",
        text2: "جميع المدرسين في مكتبنا لديهم خبرة طويلة في التعليم وذوي كفاءات تربوية واجتماعية وعلاوة على ذلك يتوسطون بين الثقافتين وبإمكانهم حل المشاكل التي تواجه الطالب مثل صعوبة الفهم للمادة العلمية أوصعوبة التفاهم مع المدرسة. ",
        text3: "في مكتينا نقدم لكم استشارة تعليمية مجانية في البداية لتحديد مستوى الطالب ونقاط ضعفه. الدروس تتم إما بشكل فردي أو جماعي ولديكم حرية الاختيار إذ يتم إعطاء الدرس في منزل الطالب أو عندنا في المكتب.",
        text4: "إذا كنت من الحاصلين على دعم مالي من الجوب سنتر أو من السوسيال بإمكانك تقديم طلب لتغطية تكاليف دروس التقوية، أما بالنسبة للأشخاص المستقلين مادياً ولا يحصلون على مساعدات من الجوب سنتر أو السوسيال يسرنا أن نقدم لهم عروض خاصة لمساعدة أطفالهم على التقدم في دروسهم وتجاوز الصعوبات التي يواجهونها أثناء الدراسة.",
        title3: "المبادئ الأساسية لدعم التعليم الناجح:",
        title4: "قُرب المكان:",
        title4Description: "بإمكانك الوصول إلى مكتبنا بالمواصلات العامة حيث يقع مكتبنا في مركز المدينة بالقرب من محطة القطار الرئيسية بمدينة لايبزيغ، ومن الممكن إعطاء الدروس في منزل الطالب.",
        title5: "إمكانية التدريس بشكل فردي:",
        title5Description: "تختلف الاحتياجات ونقاط الضعف من طالب لأخر. من خلال الدروس الفردية يتم التركيز على المواضيع والنقاط الهامة غير المفهومة للطالب والمشاكل التي يواجهها. أولويتنا القصوى هي تحقيق النجاح المستمر والتقدم العلمي وتقديم المساعدة للطالب إلى أن يتجاوز الصعوبات التي يواجهها أثناء الدراسة.",
        title6: "التوسط بين الثقافات:",
        title6Description: "كمكتب ترجمة نقدم العديد من الخدمات اللغوية ولدينا خبرة طويلة في الوساطة بين الثقافتين، ونساعد الطلاب ليس فقط في فهم المادة، بل أيضا في التغلب على المشاكل اللغوية أو الثقافية التي تواجههم في المدرسة.",
        contactTitle: "معلومات الإتصال:",
        contactPerson: " دانا الخوام ",
        role: "مديرة المكتب والمسؤولة عن خدمة العملاء",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "nachhilfe@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    family: [{
        title: "إجراءات لم الشمل",
        titleImage: "",
        titleDescription1: "هل تريد انجاز معاملة لم الشمل بأسرع وأضمن طريقة وتحتاج لمساعدة في الإجراءات المعقدة؟",
        titleDescription2: "نحن بخدمتكم لأنها بالفعل إجراءات لم الشمل هي من أكثر الإجراءات التي تحتاج لوقت ومجهود كبير لذلك تستطيع الإعتماد على مكتبنا في الإجراءات الكاملة المتعلقة بلم الشمل من سوريا إلى ألمانيا.",
        title2: "مكتبنا يقدم لكم المساعدة في كل الإجراءات التالية:",
        title2Description1: "المساعدة في إنهاء إجراءات الوكالة المتعلقة بلم الشمل (وكالة الزواج، وكالة الذهاب للسفارة السورية) وذلك بالتعاون مع مكاتب محاماة",
        title2Description2: "تثبيت الزواج بسوريا",
        title2Description3: "استخراج و تصديق جميع الأوراق المطلوبة للم الشمل ",
        title2Description4: "حجز موعد بالسفارة الألمانية",
        title2Description5: "إرسال و إستلام جميع الأوراق المطلوبة",
        title2Description6: "ملئ جميع الاستمارات اللازمة",
        title2Description7: "متابعة جميع أوراق لم الشمل",
        title2Description8: "المساعدة في تقديم الطلب للسفارة الألمانية",
        title2Description9: "تحضير للمقابلة في السفارة الألمانية",
        title2Description10: "حجز السيارة خاصة للذهاب والعودة من السفارة الألمانية",
        title2Description11: "الطعن في حالة قرار رفض لم الشمل بالتعاون مع مكتب محاماة في برلين متخصص بهذه القضايا",
        title2Description12: "المساعدة بالتدريب على تعلم اللغة الألمانية بعد الوصول إلى ألمانيا",
        text: "إن هذه الخدمات نقوم بتقديمها وفق عقد تقديم الخدمة الذي يحدد ما هي الخدمات التي ترغب بالحصول عليها فيما يتعلق بإجراءات لم الشمل، أي أنك تستطيع إختيار خدمات محددة و ليس بالضرورة جميع الخدمات الواردة أعلاه.",
        contactTitle: "معلومات الإتصال:",
        contactPerson: "إبراهيم المقداد",
        role: "مترجم",
        contactPersonImage: "/images/team/ibraheim_al_mokdad.jpg",
        contactPersonEmail: "f.nachzug@kaza-sprachdienste.de",
        contactPersonTel: "016099633317",
    }],
    documentPageData: [{
        title: "استخراج الأوراق وتصديقها",
        titleImage: "/images/documents.jpeg",
        titleDescription: "",
        title2: "",
        title2Description1: "",
        title2Description2: "",
        title2Description3: "",
        title2Description4: "",
        title2Description5: "",
        title2Description6: "",
        title2Description7: "",
        text: "",
        title3: "",
        title3Description1: "",
        title3Description2: "",
        contactTitle: "معلومات الإتصال:",
        contactPerson: " دانا الخوام ",
        role: "مديرة المكتب والمسؤولة عن خدمة العملاء",
        contactPersonImage: "/images/team/DanaAlkhawam.png",
        contactPersonEmail: "info@kaza-sprachdienste.de",
        contactPersonTel: "01711976204",
    }],
    offerPageData: [{
        title_: "طلب عرض سعر",
        greeting_: "اللقب",
        Mrs_: "السيدة",
        Mr_: "السيد",
        company_: "الشركة",
        firstName_: "الاسم الأول",
        lastName_: "إسم العائلة",
        email_: "عنوان البريد الإلكتروني",
        phone_: "رقم الهاتف",
        city_: "المدينة",
        street_: "اسم الشارع",
        hausNumber_: "رقم المنزل",
        plz_: "الرمز البريدي",
        services_: "الخدمات المطلوبة:",
        serviceLesson_: ["دروس التقوية"],
        serviceFamily: ["لم الشمل"],
        serviceCountry_: "الدولة",
        serviceCountryOption_: ["---الرجاء إختيار---", "سوريا", "مصر", "لبنان"],
        servciePrivateLesson_: "دروس التقوية",
        privatelessonOption_: ["---الرجاء إختيار---", "الرياضيات", "الفيزياء", "الكيمياء", "علم الأحياء", "اللغة الألمانية", "اللغة الإنجليزية", "اللغة الفرنسية", "اللغة الإسبانية", "التربية الاجتماعية", "التاريخ", "الجغرافيا", "غيرها"],
        serviceDocuments_: "إستخراج أوراق رسمية ثبوتية",
        serviceDocumentsOption_:  ["---الرجاء إختيار---",
        "الوثائق الحكومية السورية",
        "بيان عائلي",
         "بيان ولادة",
         "إخراج قيد فردي",
         "إخراج قيد عائلي",
         "بيان وفاة",
        "بيان زواج" ,
        "عقد زواج",
         "بيان طلاق",
         "وثيقة طلاق",
         "تسجيل زواج",
        " تسجيل مولود",
        "الشهادة المدرسية" ,
        "شهادات المعاهد و الجامعات",
        "مصدقة التخرج",
        " كشف العلامات",
        " توصيف المواد",
        "شهادات الخبرة",
        "شهادة لا حكم عليه",
        "أوراق أخرى",
        "الوثائق الحكومية المصرية",
        "شهادات الميلاد",
        "تسجيل مواليد الخارج",
        "الزواج",
        "توثيق الزواج",
        "الجنسية المصرية",
        "الطلاق",
        "الوفاة",
        "القيد الفردي والعائلي",
        " شهادات المدارس ",
        " شهادات المعاهد ",
        " شهادات الجامعات",
        " كشف المواد وبيان الدرجات",
        "الصحيفة الجنائية (فيش وتشبيه)",
        "خدمات أخرى"],
        serviceWithTranslation_: "مترجمة إلى اللغة الألمانية",
        serviceWithAuthentication_: "مصدقة من السفارة الألمانية في بيروت",
        serviceWithAuthentication_2: "مصدقة من السفارة الألمانية في القاهرة",
        servicesInterpretation_: ["---الرجاء الإختيار---", "الترجمة التحريرية", "الترجمة الشفهية"],
        translationsFrom_: "الترجمة من",
        translationsTo_: "الترجمة إلى",
        languageOption1_: ["---الرجاء إختيار---", "أذريجيانية" , "اردو" , "أرمنية" , "إسبانية" , "أستونية" , "أفريكيانية" , "أفغانية" , "ألبانية" , "ألمانية" , "أمهرية" , "إنجليزية" , "أندونيسية" , "أوكرانية" , "إيطالية" , "برتغالة" , "بشتونية" , "بلغارية" , "بنجابي" , "بنغالي" , "بوسنية" , "بولندية" , "بيلاروسية" , "تايلاندية" , "تركية" , "تشيكية" , "جورجية" , "داري" , "دنماركية" , "روسية" , "رومانية" , "سلوفاكية" , "سلوفينية" , "سويدية" , "صربوكرواتية" , "صربية" , "صينية" , "عربية" , "فارسي" , "فرنسية" , "فنلندية" , "فيتنامية" , "كازاخية" , "كردية" , "كرواتية" , "كورية" , "لاتفية" , "ليتوانية" , "مقدونية" , "منتنجرية" , "نرويجية" , "هندية" , "هنغالرية" , "هولندية" , "يابانية" , "يونانية"],
        languageOption2_: ["---الرجاء إختيار---", "أذريجيانية" , "اردو" , "أرمنية" , "إسبانية" , "أستونية" , "أفريكيانية" , "أفغانية" , "ألبانية" , "ألمانية" , "أمهرية" , "إنجليزية" , "أندونيسية" , "أوكرانية" , "إيطالية" , "برتغالة" , "بشتونية" , "بلغارية" , "بنجابي" , "بنغالي" , "بوسنية" , "بولندية" , "بيلاروسية" , "تايلاندية" , "تركية" , "تشيكية" , "جورجية" , "داري" , "دنماركية" , "روسية" , "رومانية" , "سلوفاكية" , "سلوفينية" , "سويدية" , "صربوكرواتية" , "صربية" , "صينية" , "عربية" , "فارسي" , "فرنسية" , "فنلندية" , "فيتنامية" , "كازاخية" , "كردية" , "كرواتية" , "كورية" , "لاتفية" , "ليتوانية" , "مقدونية" , "منتنجرية" , "نرويجية" , "هندية" , "هنغالرية" , "هولندية" , "يابانية" , "يونانية"],
        numberOfDocuments: "العدد المطلوب",
        numberOfDocumentsOption: ["---الرجاء إختيار---", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        message: "رسالتك",
        attachDocuments: "اختر الملفات",
        privacyPolicy: "لقد قرأت ووافقت على سياسة الخصوصية",
        privacyPolicyDescription: "أوافق على جمع بياناتي من نموذج الاتصال ومعالجتها للرد على طلبي. سيتم حذف البيانات بعد معالجة طلبك. ملاحظة: يمكنك إلغاء موافقتك في أي وقت في المستقبل عن طريق إرسال بريد إلكتروني إلى info@kaza-sprachdienste.de. يمكن العثور على معلومات مفصلة حول التعامل مع بيانات المستخدم في إعلان حماية البيانات الخاص بنا.",
        sendButton: "أرسل الطلب",
    }],
    visionPageData: [{
        title: "رؤيتنا",
        text1: "نحن مكتب للخدمات اللغوية والتعليمية بمدينة لايبزيغ، ونقدم خدمات الترجمة التحريرية المعتمدة والشفوية وخدمات إضافية خاصة لعملائنا العرب، منها استخراج الوثائق والشهادات من سوريا ومصر والمساعدة في إجراءات لم الشمل. علاوة على ذلك يشمل مكتبنا مركز تعليمي لدروس التقوية في جميع المواد التعليمية لكافة المستويات.",
        text2: "يقع مكتبنا بمدينة لايبزيغ في ألمانيا وقد أسس في عام 2019 من قبل الأستاذ أحمد عبد العال بعد ما عمل لسنوات طويلة كمترجم ومدرس لغة في دول مختلفة.",
        text3: "كان ومازال هدف المكتب الاندماج عن طريق اللغة والثقافة، ولتحقيق هذا الهدف يتعاون مكتبنا مع المترجمين المؤهلين والمحترفين إلى أن أصبح مكتبنا شركة معتمدة ومعروفة بخدماتها اللغوية المميزة لأغلب اللغات المطلوبة.",
        text6: "هدفنا هو تحقيق الاندماج في الحياة بألمانيا عن طريق اللغة والثقافة وعلاوة على ذلك نركز على تدريس اللغة والثقافة الألمانية للعملاء من دول عربية ومن كل دول العالم، وقد وسعنا خدماتنا بإضافة عدة لغات بسبب الحاجة الكبيرة إلى الترجمة من أجل التواصل بين الثقافات.",
        title1: "المبادئ الثلاثة لرؤيتنا حول الاندماج عن طريق اللغة والثقافة كما يلي:",
        title1Description1: "إزالة العقبات اللغوية من خلال خدمة الترجمة التحريرية",
        title1Description2: "التفاهم والثقة بين الثقافات من خلال خدمة الترجمة الشفوية",
        title1Description3: "المساعدة في الوصول إلى ألمانيا من خلال خدمة برنامج لم الشمل والمرافقة وتقديم الإستشارة لعملائنا العرب",
        text4: "من خلال خبراتنا الطويلة في مجال الخدمات اللغوية نقدم لطلابنا في دروس التقوية الطريقة المثلى للتواصل الثقافي والفهم اللغوي في جميع المواد التعليمية.",
        text5: "هدفنا الثقة المتبادلة وإرضاء عملائنا أفراد، شركات، مؤسسات، ودوائر رسمية داخل ألمانيا ودول الإتحاد الأوروبي وغيرها من الدول العربية.",

    }]
}]
